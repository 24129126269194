import React from "react";
import Logo from "../../images/root_logo.png";
import "./Footer.css";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="footer_row">
        <p className="copyright">©2020, Hindu Live</p>
        <p>
          POWERED BY <img src={Logo} alt="logofooter" />
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
